import React from 'react';
import {
	Button,
	Checkbox,
	Form,
	Icon,
	Input,
	message,
	Dropdown,
	DatePicker,
	Menu,
	Upload,
	Select,
	Col,
	Layout,
	Row
} from 'antd';
import { Redirect } from 'react-router-dom';
import { Link } from 'react-router-dom';
import * as moment from 'moment';
import { auth, database } from '../firebase/firebase';
import HorizontalDefault from "./Topbar/HorizontalDefault/index";
import Moment from "moment";

import { connect } from 'react-redux';
import {
	hideMessage,
	showAuthLoader,
	userFacebookSignIn,
	userGithubSignIn,
	userGoogleSignIn,
	userSignUp,
	userSignIn,
	userTwitterSignIn
} from 'appRedux/actions/Auth';

import IntlMessages from 'util/IntlMessages';
import CircularProgress from 'components/CircularProgress/index';


const FormItem = Form.Item;
const Option = Select.Option;

function getBase64(img, callback) {
	const reader = new FileReader();
	reader.addEventListener('load', () => callback(reader.result));
	reader.readAsDataURL(img);
}

function beforeUpload(file) {
	const isJPG = file.type === 'image/jpeg';
	if (!isJPG) {
		message.error('You can only upload JPG file!');
	}
	const isLt2M = file.size / 1024 / 1024 < 2;
	if (!isLt2M) {
		message.error('Image must smaller than 2MB!');
	}
	return isJPG && isLt2M;
}

class SignUp extends React.Component {
	handleChange = (info) => {
		if (info.file.status === 'uploading') {
			this.setState({ loading: true });
			return;
		}
		if (info.file.status === 'done') {
			// Get this url from response in real world.
			getBase64(info.file.originFileObj, (imageUrl) =>
				this.setState({
					imageUrl,
					loading: false
				})
			);
		}
	};
	handleSexClick(e) {
		//console.log(e);
		//console.log(e);
		this.setState({ sex: e });
	}
	handleStateClick(e) {
		this.setState({ state: e });
	}

	setRedirect = () => {
		this.setState({
			redirect: true
		});
	};
	renderRedirect = () => {
		if (this.state.redirect) {
			return <Redirect to="/profile" />;
		}
	};

	handleSignIn = (err, values) => {
		//console.log('data');
		//console.log(err);
		//console.log(values);
		if (!err) {
			var _this = this;
			//console.log('CALLING IF');
			if (values.avatar) {
				values.avatar = 'https://firebasestorage.googleapis.com/v0/b/med-ev-qa.appspot.com/o/avatar-doctor.jpg?alt=media';
			}
			//if(values.age)
			this.setState({ sex: values.sex, state: values.state });
			//console.log('CALLING LOADER');
			//this.props.showAuthLoader();
			
			this.setState({ values: values });
			var age = values.age
			values.age = age.format("DD/MM/YYYY")
		
			console.log(values);
			database.collection('users').where('email', '==', values.email).get().then(function(snapshot) {
				if (snapshot.docs.length == 0) {
					//console.log(Moment().format("DD/MM/YYYY'T'hh:mm:ss"))
					if(values.password.length >= 6){
					values.registration_date = Moment().format("DD/MM/YYYY'T'hh:mm:ss")
					database
						.collection('users')
						.add(values)
						.then(function(docRef) {
							console.log('WRITING USER DATA DONE');
							_this.props.userSignUp(values);
						})
						.catch(function(error) {
							//console.error('Error adding document: ', error);
						});
				} else {
					console.log('Short Password');
					message.error('La contraseña debe de tener al menos 6 caracteres');

					//_this.props.alertMessage = 'Este usuario ya esta registrado';
				}
			}else{
				message.error('Este usuario ya esta registrado');
			
			}
			});
		} else {
			//console.log('ERROR IS NOT NULL');
		}
	};

	handleSubmit = (e) => {
		e.preventDefault();
		this.props.form.validateFields((err, values) => {
			this.handleSignIn(err, values);
			////console.log(moment(values.birthdate).format('MM/DD/YYYY'));
		});
	};

	constructor() {
		super();
		this.state = {
			loading: false,
			sex: 'Masculino',
			state: 'Aguascalientes',
			redirect: false,
			signing: false,
			values: {}
			//email: 'demo@example.com',
			//password: 'demo#123'
		};
		this.handleSubmit = this.handleSubmit.bind(this);
		this.setRedirect = this.setRedirect.bind(this);
		this.handleSignIn = this.handleSignIn.bind(this);

		this.handleSexClick = this.handleSexClick.bind(this);
		this.handleStateClick = this.handleStateClick.bind(this);
	}

	componentDidMount() {
		this.setState({ sex: 'Masculino', state: 'Aguascalientes' });
	}

	componentDidUpdate() {
		//console.log("V1.233")
		if (this.props.showMessage) {
			setTimeout(() => {
				this.props.hideMessage();
			}, 100);
		}

		//console.log(this.props);
		//console.log(this.state);
		if (!this.state.signing) {
			if (this.props.authUser !== null) {
				auth.onAuthStateChanged((user) => {
					if (user) {
						//console.log('AUTH USER IS ALL RIGHT');
						//this.props.history.push('/');
						//console.log('redirecting...');
						this.setRedirect();
					} else {
						if (this.state.values.email && this.state.values.password) {
							var _this = this;
							this.setState({ signing: true });
							//console.log(this.props.authUser);
							//console.log('AUTH USER IS NOT NUL BUT FIREBASE IS; SIGNING IN....');
							auth
								.signInWithEmailAndPassword(this.state.values.email, this.state.values.password)
								.then((authUser) => {
									this.props.history.push('//');
								})
								.catch((error) => error);
						} else {
							//console.log('EMPTY VALUES');
						}
					}
				});
			} else {
				//console.log('AUTH USER IS NULL');
			}
		}
	}

	render() {
		const { getFieldDecorator } = this.props.form;
		const { showMessage, loader, alertMessage } = this.props;

		const imageUrl = this.state.imageUrl;
		return (
			<div className="gx-app-login-wrap" >
			<HorizontalDefault/>
			<div className="gx-app-login-wrap" id="login-cont">

				<div className="gx-app-login-container">
					
					<div className="gx-app-login-main-content">
						<div className="gx-app-logo-content">
							<div className="gx-app-logo-content-bg">
								{/*  <img src='https://via.placeholder.com/272x395' alt='Neature'/> */}
							</div>
							<div className="gx-app-logo-wid">
								<h1>Bienvenidos</h1>

								<p>
									El Sistema Interactivo de Evaluación Médica (SIEM) tiene como propósito principal
									ofrecer una herramienta práctica para facilitar la autoevaluación y la obtención de
									puntaje con fines de recertificación por parte de los principales Consejos de
									medicina en México.
									<br />
									<br />
									Los constantes progresos de la medicina en todas sus disciplinas y ramas, hacen
									indispensable la constante actualización, particularmente en lo que se refiere a los
									avances en los abordajes diagnósticos y terapéuticos de las entidades patológicas
									más frecuentes en el ejercicio clínico.
									<br />
									<br />
									El contenido de los programas que conforman las evaluaciones del SIEM, son
									desarrollados por médicos expertos en cada tema y supervisados por los Colegios y
									Sociedades, quienes otorgan su aval académico.
									<br />
									<br />
									Esperamos que el SIEM, sirva como un puente que acorte las distancias entre los
									médicos que día tras día se afanan en ofrecer a sus pacientes una atención óptima
									para devolverles la salud, y las instituciones académicas, gremiales y normativas,
									comprometidas con la mejora permanente de la calidad de la atención médica.
								</p>
							</div>
							<div className="gx-app-logo">
								<img alt="example" src={require('assets/images/logo.png')} />
							</div>
						</div>
						{this.renderRedirect()}

						<div className="gx-app-login-content">
							<Row>
								<Col span="8" />
								<Col span="8">
									{' '}
									<img alt="example" src={require('assets/images/asset_3.png')} />
								</Col>
								<Col span="8" />
							</Row>
							<br />
							<Form onSubmit={this.handleSubmit} className="gx-signup-form gx-form-row0">
								<Row>
									<Col xl={12} lg={12} md={12} sm={12} xs={12}>
										<FormItem>
											{getFieldDecorator('name', {
												rules: [ { required: true, message: 'Por favor escribe tu nombre' } ]
											})(<Input placeholder="Nombre" />)}
										</FormItem>
										<FormItem>
											{getFieldDecorator('paternal_lastname', {
												rules: [ { required: true, message: 'Ingrese su apellido paterno' } ]
											})(<Input placeholder="Apellido Paterno" />)}
										</FormItem>

										<FormItem>
											{getFieldDecorator('maternal_lastname', {
												rules: [ { required: true, message: 'Ingrese su apellido materno' } ]
											})(<Input placeholder="Apellido Materno" />)}
										</FormItem>
										<FormItem>
											{getFieldDecorator('sex', {
												rules: [ { required: true, message: 'Por favor seleccione su sexo' } ]
											})(
												<Select
													name="sex"
													onChange={this.handleSexClick}
													value={this.state.sex}
													placeholder="Sexo"
												>
													<Option value="Masculino">Masculino</Option>
													<Option value="Femenino">Femenino</Option>
												</Select>
											)}
										</FormItem>

										<FormItem>
											{getFieldDecorator('age', {
												rules: [
													{
														required: true,
														message: 'Por favor seleccione su fecha de nacimiento'
													}
												]
											})(
												<DatePicker
													className="gx-mb-3 gx-w-100"
													showTime
													format="DD/MM/YYYY"
													placeholder="Fecha de nacimiento"
								 				/>
											)}
										</FormItem>

										<FormItem>
											{getFieldDecorator('state', {
												rules: [ { required: true, message: 'Por favor ingrese su estado' } ]
											})(
												<Select
													name="state"
													onChange={this.handleStateClick}
													value={this.state.state}
													placeholder="Estado"
												>
													<Option value="Aguascalientes">Aguascalientes</Option>

													<Option value="Baja California">Baja California</Option>

													<Option value="Baja California Sur">Baja California Sur</Option>

													<Option value="Campeche">Campeche</Option>

													<Option value="Coahuila de Zaragoza">Coahuila</Option>

													<Option value="Colima">Colima</Option>

													<Option value="Chiapas">Chiapas</Option>

													<Option value="Chihuahua">Chihuahua</Option>

													<Option value="Ciudad de México">Ciudad de México</Option>

													<Option value="Durango">Durango</Option>

													<Option value="Guanajuato">Guanajuato</Option>

													<Option value="Guerrero">Guerrero</Option>

													<Option value="Hidalgo">Hidalgo</Option>

													<Option value="Jalisco">Jalisco</Option>

													<Option value="México">México</Option>

													<Option value="Michoacán">Michoacán</Option>

													<Option value="Morelos">Morelos</Option>

													<Option value="Nayarit">Nayarit</Option>

													<Option value="Nuevo León">Nuevo León</Option>

													<Option value="Oaxaca">Oaxaca</Option>

													<Option value="Puebla">Puebla</Option>

													<Option value="Querétaro">Querétaro</Option>

													<Option value="Quintana Roo">Quintana Roo</Option>

													<Option value="San Luis Potosí">San Luis Potosí</Option>

													<Option value="Sinaloa">Sinaloa</Option>

													<Option value="Sonora">Sonora</Option>

													<Option value="Tabasco">Tabasco</Option>

													<Option value="Tamaulipas">Tamaulipas</Option>

													<Option value="Tlaxcala">Tlaxcala</Option>

													<Option value="Veracruz">Veracruz</Option>

													<Option value="Yucatán">Yucatán</Option>

													<Option value="Zacatecas">Zacatecas</Option>
												</Select>
											)}
										</FormItem>
									</Col>

									<Col xl={12} lg={12} md={12} sm={12} xs={12}>
										<FormItem>
											{getFieldDecorator('city', {
												rules: [ { required: true, message: 'Por favor ingresa su ciudad' } ]
											})(<Input placeholder="Ciudad" />)}
										</FormItem>
										<FormItem>
											{getFieldDecorator('license_number', {
												rules: [ { required: true, message: 'Ingrese su cédula profesional' } ]
											})(<Input placeholder="Cédula profesional" />)}
										</FormItem>
										<FormItem>
											{getFieldDecorator('speciality', {
												rules: [ { required: true, message: 'Ingrese su especialidad' } ]
											})(<Input placeholder="Especialidad" />)}
										</FormItem>
										<FormItem>
											{getFieldDecorator('subspeciality', {
												rules: [ { required: true, message: 'Ingrese su subespecialidad' } ]
											})(<Input placeholder="Sub-especialidad" />)}
										</FormItem>

										{/* <span>Fotografía de perfil</span> */}

										{/* <FormItem>
									{getFieldDecorator('avatar', {
										rules: [
											{
												required: false,
												message: 'Please upload your avatar!'
											}
										]
									})(
										<Upload
											name="avatar"
											listType="picture-card"
											className="avatar-uploader"
											showUploadList={false}
											//action="//jsonplaceholder.typicode.com/posts/"
											beforeUpload={this.beforeUpload}
											onChange={this.handleChange}
										>
											{imageUrl ? <img src={imageUrl} alt="avatar" /> : uploadButton}
										</Upload>
									)}
								</FormItem> */}
										<FormItem>
											{getFieldDecorator('email', {
												rules: [
													{
														required: true,
														type: 'email',
														message: 'The input is not valid E-mail!'
													}
												]
											})(<Input placeholder="Email" />)}
										</FormItem>
										<FormItem>
											{getFieldDecorator('password', {
												rules: [ { required: true, message: 'Please input your Password!' } ]
											})(<Input type="password" placeholder="Password" />)}
										</FormItem>
									</Col>
								</Row>
								<FormItem>
									{getFieldDecorator('remember', {
										valuePropName: 'checked',
										initialValue: true
									})(
										<Checkbox>
											<IntlMessages id="appModule.iAccept" />
										</Checkbox>
									)}
									<span className="gx-link gx-signup-form-forgot">
										<IntlMessages id="appModule.termAndCondition" />
									</span>
								</FormItem>
								<FormItem>
									<Button
										type="primary"
										className="gx-mb-0"
										htmlType="submit"
										onClick={this.handleSubmit}
									>
										<IntlMessages id="app.userAuth.signUp" />
									</Button>
									<span>
										<IntlMessages id="app.userAuth.or" />
									</span>{' '}
									<Link to="/signin">
										<IntlMessages id="app.userAuth.signIn" />
									</Link>
								</FormItem>
							</Form>
						</div>

						{loader && (
							<div className="gx-loader-view">
								<CircularProgress />
							</div>
						)}
						{showMessage && message.error(alertMessage)}
					</div>
				</div>
				</div>
			</div>
		);
	}
}

const WrappedSignUpForm = Form.create()(SignUp);

const mapStateToProps = ({ auth }) => {
	const { loader, alertMessage, showMessage, authUser } = auth;
	return { loader, alertMessage, showMessage, authUser };
};

export default connect(mapStateToProps, {
	userSignUp,
	hideMessage,
	userSignIn,
	showAuthLoader,
	userFacebookSignIn,
	userGoogleSignIn,
	userGithubSignIn,
	userTwitterSignIn
})(WrappedSignUpForm);
