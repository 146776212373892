import React from "react";
import {Button, Progress, Link} from "antd";
import { stat } from "fs";


const Evaluation = ({seriesId, evid, product, grid, solvedEv}) => {
  const { image, total, title} = product;
  const { attempt_number, porcentage, grade, correct_answers, status} = solvedEv[0]?solvedEv[0].data():"";
  //console.log("EV Component")
  //console.log(solvedEv[0])

  return (
    <div className={`gx-product-item  ${grid ? 'gx-product-vertical' : 'gx-product-horizontal'}`}>
      <div className="gx-product-image" >
        <div className="gx-grid-thumb-equal shorten">
          <span className="gx-link gx-grid-thumb-cover ">
            <img src={image}/>
          </span>
        </div>
      </div>

      <div className="gx-product-body">
        <h3 className="gx-product-title">{title}
        </h3>
        <h3>Intento: {attempt_number?attempt_number:"1"}  de 3
        </h3>
    
        <Progress percent={(porcentage?porcentage:0)} status="active"/>
        <p>Porcentaje de avance</p>
        <Progress percent={(grade?grade:0)}  />
        <p>Porcentaje de aciertos</p>
      </div>
    

      <div className="gx-product-footer">
      <Button type="dashed">{(status?status:"En curso")}</Button>
      {((status != null && status == "En curso" || status == "Por iniciar")?
        <Button type="primary"  href = {"/series/"+seriesId+"/evaluations/"+evid} >Ver las preguntas</Button>:null)
      }
        {((status != null && status == "Reprobado" && attempt_number!='3')?
        <Button type="primary"  href = {"/series/"+seriesId+"/evaluations/"+evid} >Volver a intentar</Button>:null)
      }
      
      </div>
    </div>
  )
};

export default Evaluation;

