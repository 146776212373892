import React, { Component } from 'react';
import { Col, Row, Card, Button, Icon } from 'antd';
import Evaluation from 'components/eCommerce/Evaluation';
import { auth, database } from '../../../firebase/firebase';
import { cold } from 'react-hot-loader';
import Moment from 'moment';
import { func } from 'prop-types';

class EvaluationsList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			user: {},
			userId: 1,
			urlId: 0,
			series: {},
			accessed_serie: {},
			solvedEvaluationsLoaded: false,
			solvedEvaluations: [],
			evaluations: []
		};
		//this.handleChange=this.handleChange.bind(this);
	}

	getSeriesInformation() {
		var _this = this;
		database.doc('/series/' + _this.state.urlId).get().then(function(doc) {
			if (doc.exists) {
				_this.setState({ series: doc.data() });
				database
					.collection('/evaluations')
					.where('series', '==', _this.state.urlId)
					.get()
					.then(function(snapshot) {
						var evs = [];
						////console.log("Before")
						////console.log(snapshot)
					
						snapshot.docs.forEach(function(damn) {
							database
								.collection('/questions')
								.where('evaluations', '==', damn.id)
								.get()
								.then(function(fack) {
									damn.questions = fack.docs;
									damn.total = fack.docs.length;
									evs.push(damn);
									if (evs.length == snapshot.docs.length) {
										var sorted = evs.sort(function(a, b) {
											if(a.data().order < b.data().order) { return -1; }
											if(a.data().order > b.data().order) { return 1; }
											return 0;
										});
										////console.log("Evaluatuion snapshot")
										////console.log(snapshot);
										_this.setState({ evaluations: sorted });
										_this.getUserEvaluations();
									}
								});
						});
					});
			} else {
				////console.log('Series does not exist');
			}
		});
	}

	getUserEvaluations() {
		var _this = this;
		database
			.collection(
				'users/' +
					_this.state.userId +
					'/accesed_series/' +
					_this.state.accessed_serie.id +
					'/solved_evaluations'
			)
			.get()
			.then(function(snapshot) {
				if (snapshot.docs.length == 0) {
					////console.log('WAS EMPTY');
					var added = [];
					_this.state.evaluations.forEach(function(doc) {
						////console.log('Object initialited+');

						database
							.collection(
								'users/' +
									_this.state.userId +
									'/accesed_series/' +
									_this.state.accessed_serie.id +
									'/solved_evaluations'
							)
							.add({
								correct_answers: '0',
								wrong_answers: '0',
								timestamp: Moment(new Date()).format('DD/MM/YYYY HH:mm:ss'),
								grade: '0',
								evaluation_id: doc.id,
								porcentage: '0',
								status: 'Por iniciar',
								attempt_number: '1',
								evaluation_name: doc.data().title
							})
							.then(function(docum) {
								////console.log('Commencing query per object');
								

								database
									.doc(
										'users/' +
											_this.state.userId +
											'/accesed_series/' +
											_this.state.accessed_serie.id +
											'/solved_evaluations/' +
											docum.id
									)
									.get()
									.then(function(itemx) {
										////console.log('Object obtaineed');
										added.push(itemx);
										////console.log(added.length);
										////console.log(_this.state.evaluations.length);
										if (added.length == _this.state.evaluations.length) {
											_this.setState({
												solvedEvaluations: added,
												solvedEvaluationsLoaded: true
											});
										}
										if(added.length == _this.state.evaluations.length-1){
											_this.setState({
												solvedEvaluations: added,
												solvedEvaluationsLoaded: true
											});
										}
									})
									.catch(function(er) {
										////console.log('Error!');
										////console.log(er);
									});
							})
							.catch(function(error) {
								window.location.reload();

								////console.log('ERROR INSERTING ATTEMPT : ');
								////console.log(error);
							});
					});
				} else {
					////console.log('NOT EMPTY');

					var finalArray = [];
					_this.state.evaluations.forEach(function(item) {
						/*var filtered = snapshot.docs.find(function(element) {
							////console.log('comparing ' + element.data().evaluation_id + ' ' + item.id);
							return element.data().evaluation_id == item.id;
						});*/
						////console.log(' array');
						////console.log(snapshot.docs);
						var filtered = snapshot.docs.filter((word) => word.data().evaluation_id == item.id);
						if (filtered.length > 1) {
							//console.log("this case is filtered")
							/*var postF = filtered.filter(
								(word) => word.data().status == 'En curso' || word.data().status == 'Por iniciar'
							);
							if (postF.length == 0) {
								postF = filtered.sort(function(a, b) {
									return parseInt(a.data().attempt_number) >= parseFloat(b.data().attempt_number);
								});
							}*/
							
							filtered.sort((a, b) => (a.data().attempt_number > b.data().attempt_number) ? 1 : -1)
							finalArray.push(filtered[filtered.length-1]);
						} else if (filtered.length == 1) {
							//console.log("this case is 1")

							finalArray.push(filtered[0]);
						} else {
							//console.log("this case is else")

							database
								.collection(
									'users/' +
										_this.state.userId +
										'/accesed_series/' +
										_this.state.accessed_serie.id +
										'/solved_evaluations'
								)
								.add({
									correct_answers: '0',
									wrong_answers: '0',
									timestamp: Moment(new Date()).format('DD/MM/YYYY HH:mm:ss'),
									grade: '0',
									evaluation_id: item.id,
									porcentage: '0',
									status: 'Por iniciar',
									attempt_number: '1',
									evaluation_name: item.data().title
								})
								.then(function(doc) {
									database.doc(doc.path).get().then(function(it) {
										finalArray.push(it);
									});
								})
								.catch(function(error) {
									////console.log('ERROR INSERTING ATTEMPT : ');
									////console.log(error);
								});
						}
					});
					////console.log('Final array');
					////console.log(finalArray);
					_this.setState({ solvedEvaluations: finalArray, solvedEvaluationsLoaded: true });
				}
			})
			.catch(function(error) {
				////console.log('ERROR INSERTING ATTEMPT : ');
				////console.log(error);
			});
	}

	getUserAccessSeries() {
		var _this = this;
		////console.log('/users/' + _this.state.userId + '/accesed_series');
		database
			.collection('users/' + _this.state.userId + '/accesed_series')
			.where('series_id', '==', _this.state.urlId)
			.get()
			.then(function(snapshot) {
				////console.log(snapshot);
				if (snapshot.docs.length == 0) {
					////console.log('NO ACCESS TO THIS Evaluation');
				} else {
					_this.setState({ accessed_serie: snapshot.docs[0] });
					_this.getSeriesInformation();
				}
			});
	}

	getUserInformation(user) {
		////console.log('ALL USER INFO CALLED');
		////console.log(user.email);
		var _this = this;
		database
			.collection('users')
			.where('email', '==', user.email)
			.get()
			.then(function(querySnapshot) {
				querySnapshot.forEach(function(doc) {
					_this.setState({ user: doc.data(), userId: doc.id });
					_this.getUserAccessSeries();
				});
			})
			.catch(function(error) {
				////console.log('User does not exist:', error);
			});
	}

	componentDidMount() {
		const { urlId } = this.props.match.params;
		var _this = this;

		_this.setState({ urlId: urlId });
		auth.onAuthStateChanged((user) => {
			if (user) {
				_this.getUserInformation(user);
			}
		});
	}

	render() {
		return (
			<div>
				    <Button type="primary" href ="/profile"> <Icon type="left" size="small" />Regresar</Button>
				<Row>
					<Col key="ss" span={16}>
						<Card className={`gx-card-metrics`}>
							<h1 className="gx-product-title">{this.state.series.sponsor}</h1>
							<h4>{this.state.series.evaluation_description}</h4>
							<p>{this.state.series.sponsor_message}</p>
							<p>{this.state.series.disclamer}</p>
						</Card>
					</Col>
					<Col key="1a" span={8}>
						<Card className={`gx-card-metrics`}>
							<img src={this.state.series.sponsor_logo} />
						</Card>
					</Col>
					{this.state.evaluations.length > 0 && this.state.solvedEvaluationsLoaded == true ? (
						this.state.evaluations.map((ev, index) => (
							<Col key={index} span={24}>
								<Evaluation
									key={ev.id}
									seriesId={this.state.urlId}
									evid={ev.id}
									product={ev.data()}
									solvedEv={this.state.solvedEvaluations.filter(
										(item) => item.data().evaluation_id == ev.id
									)}
								/>
							</Col>
						))
					) : null}
				</Row>
			</div>
		);
	}
}

export default EvaluationsList;
